import moment from "moment";

export function calcTill(): string {
  const now: any = moment();
  const target: any = moment("20300101", "YYYYMMDD");
  const diff = moment.duration(target - now);
  let arr = [
    [diff.years(), "year"],
    [diff.months(), "month"],
    [diff.days(), "day"],
    [diff.hours(), "hour"],
    [diff.minutes(), "minute"],
    [diff.seconds(), "second"],
  ]
    .filter((pair) => pair[0] !== 0)
    .map((pair) => {
      if (pair[0] === 1) {
        return `${pair[0]} ${pair[1]}`;
      } else {
        return `${pair[0]} ${pair[1]}s`;
      }
    });
  let till = "";
  if (arr.length > 1) {
    const last = arr.pop();
    till = arr.join(", ") + " and " + last + " left";
  } else if (arr.length == 1) {
    till = arr[0];
  }

  return till;
}
