import React from "react";
import { calcTill } from "../utils";

export default function App() {
  const [till, setTill] = React.useState(calcTill());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTill(calcTill());
    }, 200);

    return () => clearInterval(interval);
  }, []);

  const itemClass = "border-b border-gray-300 text-center last:border-b-0";

  return (
    <div className="h-screen bg-gray-100">
      <div className="max-w-2xl mx-auto flex flex-col items-center justify-center h-full">
        <div className="text-lg pb-8">{till}</div>
        <ul className="space-y-4 max-w-xl">
          <li className={itemClass}>
            <strong>~~WithBeta~~</strong>
          </li>
          <li className={itemClass}>
            <a href="https://SinceTill.com" target="_blank" className="link">
              Since Till
            </a>
          </li>
          <li className={itemClass}>
            <a
              className="link"
              href="https://paythemforward.com"
              target="_blank"
            >
              PayThemForward
            </a>
          </li>
          <li className={itemClass}>ThisOneLife</li>
          <li className={itemClass}>So short yet so long</li>
        </ul>
      </div>
    </div>
  );
}
